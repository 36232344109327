
import { UserModule } from '@/store/modules/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component
export default class ProposalApprovalCard extends Vue{

  @Prop({
    default: ""
  })
  public proposalId!: string;

  private approver = "";
  private isApproved = false;

  get canApprove() {
    return this.approver === UserModule.address && !this.isApproved;
  }

  private async tapApproval() {
    if (UserModule.contractKit) {
      try {
        AppModule.START_LOADING("Approving");
        const res = await UserModule.contractKit.governanceContract.approve(this.proposalId);
        AppModule.END_LOADING();
        this.loadData();
        if (res) {
          this.$emit("approved");
          AppModule.TOAST({
            type: "success",
            message: "Success"
          });
        } else {
          AppModule.TOAST({
            type: "error",
            message: "Unknown error",
          })
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "error",
          message: e.messages,
        })
      }
    }

  }

  async loadData() {
    if (UserModule.contractKit) {
      [this.approver, this.isApproved] = await Promise.all([
        UserModule.contractKit.governanceContract.getApprover(),
        UserModule.contractKit.governanceContract.isApproved(this.proposalId)
      ]);
    }
  }

  mounted() {
    this.loadData();
  }

  @Watch("proposalId")
  idChange() {
    this.loadData();
  }

};
