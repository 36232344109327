
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProposalTransactionCard extends Vue {
  private codeExpand = false;
  @Prop({
    default: ""
  })
  public transaction!: string;
};
