import { render, staticRenderFns } from "./ProposalInfoCard.vue?vue&type=template&id=448f2eb0&scoped=true&"
import script from "./ProposalInfoCard.vue?vue&type=script&lang=ts&"
export * from "./ProposalInfoCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProposalInfoCard.vue?vue&type=style&index=0&id=448f2eb0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448f2eb0",
  null
  
)

export default component.exports