<template>
  <div class='proposal-like-list-card list-card'>
    <span class='list-title'>Signer List</span>
    <table class='list-table'>
      <thead>
      <tr>
        <th>Signer</th>
        <th>Address</th>
        <th>Time</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for='item in 10' :key='item'>
        <td>
          <div class='user-info'>
            <v-avatar :size='$vuetify.breakpoint.model ? "5.33vw" : "36px"' color='grey'>
            </v-avatar>
            <span class='user-name'>Polychain Capital</span>
          </div>
        </td>
        <td class='light'>0x33e…90cd</td>
        <td class='light'>2022-05-19 16:12:39</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ProposalSignerListCard',
};
</script>

<style scoped lang='scss'>
@import "./list-style.scss";
.proposal-like-list-card {
  background: white;
  width: 150vw;
  padding: 6.933vw 6.67vw 8.267vw 7.733vw;
  border-radius: 20px;
  @include media-breakpoint-up(sm) {
    border-radius: 20px;
    padding: 26px 30px 33px 30px;
  }
}
</style>
