
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ProposalInfoCard from "@/components/proposal/ProposalInfoCard.vue";
import ProposalTransactionCard from "@/components/proposal/ProposalTransactionCard.vue";
import ProposalVotingListCard from "@/components/proposal/ProposalVotingListCard.vue";
import ProposalExecuteCard from "@/components/proposal/ProposalExecuteCard.vue";
import ProposalVoteActionCard from "@/components/proposal/ProposalVoteActionCard.vue";
import ProposalStatusCard from "@/components/proposal/ProposalStatusCard.vue";
import ProposalLikeListCard from "@/components/proposal/ProposalLikeListCard.vue";
import ProposalSignerListCard from "@/components/proposal/ProposalSignerListCard.vue";
import ProposalUpvoteCard from "@/components/proposal/ProposalUpvoteCard.vue";
import ProposalApprovalCard from "@/components/proposal/ProposalApprovalCard.vue";
import { UserModule } from "@/store/modules/user";
import BigNumber from "bignumber.js";
import { GovernanceConfig, ProposalMetadata, ProposalStage } from "@maplabs/map-atlas-sdk";
import { GovernanceModule } from "@/store/modules/governance";
import ProposalWithdrawCard from "@/components/proposal/ProposalWithdrawCard.vue";

@Component({
  components: { ProposalWithdrawCard, ProposalApprovalCard, ProposalUpvoteCard, ProposalSignerListCard, ProposalLikeListCard, ProposalStatusCard, ProposalVoteActionCard, ProposalExecuteCard, ProposalVotingListCard, ProposalTransactionCard, ProposalInfoCard },
})
export default class ProposalDetailPage extends Vue {
  @Prop({
    default: "0"
  })
  private proposalID!: string;
  private queryApi = false;
  private historyStage: ProposalStage = ProposalStage.None;
  private showWithdrawCard = false;
  private proposalMetadata: ProposalMetadata & {
    proposerName: string,
    proposerWeight: number,
    status: number,
    upvotes: number,
    upvotesUsersNum: number,
    stage: ProposalStage,
  } = {
    proposer: "",
    deposit: new BigNumber(0),
    timestamp: new BigNumber(0),
    transactionCount: 0,
    descriptionURL: "",
    proposerName: "",
    proposerWeight: 0,
    stage: ProposalStage.None,
    status: 1,
    upvotesUsersNum: 0,
    upvotes: 0,
  };
  private proposalTransaction = "";
  private approver = "";
  private userName = "";
  private config: GovernanceConfig | null = null;
  private showApproveCard = false;
  private voteData = {
    upvotes: 0,
    voters: 0,
  }

  private transaction = {
    execuedAddress: "--",
    execuedBlock: "--",
    execuedTxid: "--",
  }

  private async loadData() {
    if (UserModule.contractKit) {
      GovernanceModule.UpdateDashboardLoading(true);
      const stage = await UserModule.contractKit.governanceContract.getProposalStage(this.proposalID);
      this.showWithdrawCard = (stage !== ProposalStage.Queued);
      if (stage !== ProposalStage.Expiration) {
        this.loadWeb3Data(stage);
      } else {
        this.loadAPIData(stage);
      }
    }
  }

  private async loadWeb3Data(stage: ProposalStage) {
    const res = await Promise.all([
      UserModule.contractKit!.governanceContract.getProposalMetadata(new BigNumber(this.proposalID)),
      UserModule.contractKit!.governanceContract.getProposal(this.proposalID),
      UserModule.contractKit!.governanceContract.getConfig(),
    ]);
    this.config = res[2];
    // TODO: 除了 queued 状态,其他状态无法获取 upvotes
    this.proposalTransaction = res[1].reduce((pre, current) => {
      return pre + current.input;
    }, "");
    const res1 = await Promise.all([
      UserModule.contractKit!.accountsContract.getName(res[0].proposer),
      UserModule.contractKit!.governanceContract.getVoteWeight(res[0].proposer),
      UserModule.contractKit!.governanceContract.isApproved(this.proposalID),
    ]);
    this.showApproveCard = !res1[2];
    this.proposalMetadata = {
      ...res[0],
      proposerName: res1[0],
      proposerWeight: res1[1],
      stage,
      status: stage !== ProposalStage.Execution ? 1 : 4,
      upvotesUsersNum: 0,
      upvotes: 0,
    };
    this.historyStage = stage;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (this.$apollo.queries.proposalInfo) {
      this.$apollo.queries.proposalInfo.refetch();
    } else {
      this.$apollo.addSmartQuery('proposalInfo', {
        query: require("../../apollo/graphql/ProposalInfo.gql"),
        variables() {
          return {
            proposalId: parseInt(self.proposalID),
          };
        },
        result(res: any) {
          self.handleData(res, stage);
        },
        error() {
          GovernanceModule.UpdateDashboardLoading(false);
        }
      })
    }
  }

  private async loadAPIData(stage: ProposalStage) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (this.$apollo.queries.proposalInfo) {
      this.$apollo.queries.proposalInfo.refetch();
    } else {
      this.$apollo.addSmartQuery('proposalInfo', {
        query: require("../../apollo/graphql/ProposalInfo.gql"),
        variables() {
          return {
            proposalId: parseInt(self.proposalID),
          };
        },
        result(res: any) {
          self.handleData(res, stage);
        },
        error() {
          GovernanceModule.UpdateDashboardLoading(false);
        }
      })
    }
  }

  handleData(res: any, stage: ProposalStage) {
    if (stage !== ProposalStage.Expiration) {
      this.handleNormalData(res);
    } else {
      this.handleExpirationData(res, stage);
    }
  }

  handleNormalData(res: any) {
    if (res.data) {
      this.proposalMetadata.upvotesUsersNum = res.data.proposalInfo.upvotesUsersNum;
      this.proposalMetadata.upvotes = res.data.proposalInfo.upvotes;
      GovernanceModule.UpdateDashboardLoading(false);
    }
  }

  handleExpirationData(res: any, stage: ProposalStage) {
    const info = res.data.proposalInfo;
    this.proposalMetadata = {
      proposer: info.proposer.address,
      deposit: new BigNumber(0),
      timestamp: new BigNumber(info.stageTimestamp),
      transactionCount: info.transactionCount,
      descriptionURL: info.description,
      proposerName: info.proposer.name,
      proposerWeight: 0,
      stage,
      status: info.status,
      upvotes: info.upvotes,
      upvotesUsersNum: info.upvotesUsersNum,
    };
    this.proposalTransaction = info.transactions.reduce((pre: string, current: any) => {
      return pre + current.data;
    }, "");
    this.historyStage = this.getStage(info.stage);
    this.transaction = {
      execuedAddress: info.execuedAddress || "--",
      execuedBlock: info.execuedBlock || "--",
      execuedTxid: info.execuedTxid || "--",
    }
    GovernanceModule.UpdateDashboardLoading(false);
  }

  getStage(stage: number) {
    switch (stage) {
      case 1:
        return ProposalStage.Queued;
      case 2:
        return ProposalStage.Approval;
      case 3:
        return ProposalStage.Referendum;
      case 4:
        return ProposalStage.Execution;
      default:
        return ProposalStage.Expiration;
    }
  }

  get isQueued() {
    if (this.proposalMetadata.stage === ProposalStage.Expiration) {
      return this.historyStage === ProposalStage.Queued;
    }
    return this.proposalMetadata.stage === ProposalStage.Queued;
  }
  get isApproval() {
    if (this.proposalMetadata.stage === ProposalStage.Expiration) {
      return this.historyStage === ProposalStage.Approval || this.historyStage === ProposalStage.Referendum;
    }
    return this.proposalMetadata.stage === ProposalStage.Approval || this.proposalMetadata.stage === ProposalStage.Referendum;
  }
  get isReferendum() {
    if (this.proposalMetadata.stage === ProposalStage.Expiration) {
      return this.historyStage === ProposalStage.Referendum;
    }
    return this.proposalMetadata.stage === ProposalStage.Referendum;
  }
  get isExecution() {
    if (this.proposalMetadata.stage === ProposalStage.Expiration) {
      return this.historyStage === ProposalStage.Execution;
    }
    return this.proposalMetadata.stage === ProposalStage.Execution;
  }

  get isExpiration() {
    return this.proposalMetadata.stage === ProposalStage.Expiration;
  }

  @Watch("proposalID")
  private idChange() {
    this.loadData();
  }

  private upvoted() {
    this.loadData();
  }
  private approved() {
    this.loadData();
  }
  private voted() {
    this.loadData();
  }
  private executed() {
    this.loadData();
  }

  mounted() {
    this.loadData();
  }
};
