

import { Component, Prop, Vue } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';

@Component({
  components: { AvatarPixel },
  apollo: {
    pageVoterList: {
      query: require("../../apollo/graphql/ProposalVoteList.gql"),
      variables() {
        return {
          page: this.page,
          proposalId: parseInt(this.proposalId, 10),
        };
      }
    }
  }
})
export default class ProposalVotingListCard extends Vue {
  @Prop({
    default: "0"
  })
  private proposalId!: string;
  private page = 1;
  private pageVoterList: any = {
    currentPage: 0,
    totalCount: 0,
    pageSize: 0,
    dataList: []
  };
  get totalPage() {
    if (this.pageVoterList) {
      return Math.ceil(this.pageVoterList.totalCount / 10);
    }
    return 0;
  }
  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
  private convertOption(num: number) {
    return ['ABSTAIN', 'NO', 'YES'][num - 1];
  }
};
