
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { ellipsisThree, timestampToTime, viewportToPixels } from '@/utils/tools';
import { marked } from 'marked';
import { GovernanceConfig, ProposalStage } from "@maplabs/map-atlas-sdk";
import { UserModule } from '@/store/modules/user';
import { GovernanceModule } from '@/store/modules/governance';
import { getArticleFromURL, getArticleTitleAndContent } from '@/utils/article_tool';
import { handleClipboard } from '@/utils/clipboard';
import AvatarPixel from '@/components/AvatarPixel.vue';
@Component({
  components: { AvatarPixel },
})
export default class ProposalInfoCard extends Vue {

  @Prop({
    default() {
      return {
        proposer: "",
        proposerName: "",
      };
    }
  })
  public metadata: any;

  private config: GovernanceConfig | null = null;
  private createTime = "";
  private expirationTime = "";
  private proposalTitle = "";

  @Ref()
  private proposalEle?: HTMLElement;

  private chunkAddress(address: string) {
    return ellipsisThree(address);
  }

  get loading() {
    return GovernanceModule.proposalDetailLoading;
  }

  @Watch("metadata")
  async metadataChange(newVal: any) {
    if (newVal.descriptionURL) {
      const data = await Promise.all([
        getArticleFromURL(newVal.descriptionURL),
        UserModule.contractKit!.governanceContract.getConfig()
      ]);
      this.config = data[1];
      if (this.proposalEle) {
        const {
          title,
          content
        } = getArticleTitleAndContent(data[0].data);
        this.proposalTitle = title;
        this.proposalEle.innerHTML = marked.parse(content);
      }
      this.createTime = timestampToTime(this.metadata.timestamp.toNumber(), '.');
      switch (this.metadata.stage) {
        case ProposalStage.Queued:
          this.expirationTime = timestampToTime(this.metadata.timestamp.toNumber() + this.config.dequeueFrequency.toNumber(), ".");
          break;
        case ProposalStage.Referendum:
          this.expirationTime = timestampToTime(this.metadata.timestamp.toNumber() + this.config.stageDurations.Referendum.toNumber(), ".");
          break;
        case ProposalStage.Execution:
          this.expirationTime = timestampToTime(this.metadata.timestamp.toNumber() + this.config.stageDurations.Referendum.toNumber() + this.config.stageDurations.Execution.toNumber(), ".");
          break;
        default:
          this.expirationTime = timestampToTime(this.metadata.timestamp.toNumber() + this.config.stageDurations.Referendum.toNumber() + this.config.stageDurations.Execution.toNumber(), ".");
          break;
      }
    }
  }

  get statusText() {
    switch (this.metadata.stage) {
      case ProposalStage.Queued:
      case ProposalStage.Execution:
      case ProposalStage.Approval:
      case ProposalStage.Referendum:
        return "Ongoing";
      case ProposalStage.Expiration:
        return "Expiration";
    }
  }

  private convertViewPort(input: string): number {
    return viewportToPixels(input);
  }

  private tapCopy(event: any) {
    handleClipboard(this.metadata.proposer, event);
  }
};
