import { Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface IGovernanceState {
  proposalDetailLoading: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'governance',
})
class Governance extends VuexModule implements IGovernanceState {
  public proposalDetailLoading = false;

  @Mutation
  public UpdateDashboardLoading(loading: boolean) {
    this.proposalDetailLoading = loading;
  }
}

export const GovernanceModule = getModule(Governance);
// export default User;
