
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProposalStage } from '@maplabs/map-atlas-sdk';

@Component
export default class ProposalStatusCard extends Vue{
  @Prop({
    default: ProposalStage.Queued,
  })
  public stage!: ProposalStage;
  private currentStep = 1;

  @Watch('stage')
  stageChange() {
    this.initStep();
  }

  initStep() {
    if (this.stage === ProposalStage.Expiration) {
      this.currentStep = 4;
      return;
    }
    const stages = [ProposalStage.Queued, ProposalStage.Approval, ProposalStage.Referendum, ProposalStage.Execution];
    this.currentStep = stages.indexOf(this.stage) + 1;
  }

  mounted() {
    this.initStep();
  }

};
