

import { Component, Prop, Vue } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';

@Component({
  components: { AvatarPixel },
  apollo: {
    pageLikeList: {
      query: require("../../apollo/graphql/ProposalLikeList.gql"),
      variables() {
        return {
          page: this.page,
          proposalId: parseInt(this.proposalId, 10),
        };
      }
    }
  }
})
export default class ProposalLikeListCard extends Vue {
  @Prop({
    default: "0"
  })
  private proposalId!: string;
  private page = 1;
  private pageLikeList: any = {
    currentPage: 0,
    totalCount: 0,
    pageSize: 0,
    dataList: []
  };

  get totalPage() {
    if (this.pageLikeList) {
      return Math.ceil(this.pageLikeList.totalCount / 10);
    }
    return 0;
  }
  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
};
