
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { AppModule } from '@/store/modules/app';

@Component
export default class ProposalUpvoteCard extends Vue {
  @Prop({
    default: ""
  })
  public proposalId!: string;

  private async tapWithdraw() {
    if (UserModule.contractKit) {
      try {
        const res = await UserModule.contractKit.governanceContract.withdraw();
        AppModule.END_LOADING();
        if (res) {
          AppModule.TOAST({
            type: 'success',
            message: 'Upvote success!'
          });
          this.$emit("upvoted");
          // this.loadData();
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: 'error',
          message: e.message
        });
      }
    }
  }
  private async loadData() {
    if (UserModule.contractKit) {
      this.upvoted = await UserModule.contractKit.governanceContract.isUpvoting(UserModule.address);
    }
  }
  mounted() {
    // this.loadData();
  }
  @Watch("proposalId")
  idChange() {
    // this.loadData();
  }
};
