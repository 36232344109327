
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { AppModule } from '@/store/modules/app';

@Component
export default class ProposalExecuteCard extends Vue {
  @Prop()
  private proposalId!: string;
  @Prop()
  private transaction: any;
  @Prop({
    default: true,
  })
  private isExpiration!: boolean;

  async tapExecute() {
    if (UserModule.contractKit) {
      try {
        AppModule.START_LOADING("Executing");
        const res = await UserModule.contractKit.governanceContract.execute(this.proposalId);
        AppModule.END_LOADING();
        if (res) {
          this.$emit("executed");
          AppModule.TOAST({
            type: "success",
            message: "Success",
          });
        } else {
          AppModule.TOAST({
            type: "error",
            message: "Unknown error",
          });
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "error",
          message: e.message,
        });
      }
    }
  }

  mounted() {
    console.log(this.proposalId);
  }

};
