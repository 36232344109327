
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { AppModule } from '@/store/modules/app';

@Component
export default class ProposalUpvoteCard extends Vue {
  @Prop({
    default: ""
  })
  public proposalId!: string;

  @Prop({
    default() {
      return {
        upvotes: 0,
        upvotesUsersNum: 0,
      }
    }
  })
  private metadata: any;

  private upvoted = true;

  private async tapUpvote() {
    // fetch locked map
    if (UserModule.contractKit) {
      AppModule.START_LOADING("Upvoting");
      const locked = await UserModule.contractKit.lockedContract.getAccountTotalLockedGold(UserModule.address);
      if (locked <= 0) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: 'error',
          message: 'You cannot upvote without locking gold'
        });
        return;
      }
      try {
        const res = await UserModule.contractKit.governanceContract.upvote(this.proposalId, UserModule.address);
        AppModule.END_LOADING();
        if (res) {
          AppModule.TOAST({
            type: 'success',
            message: 'Upvote success!'
          });
          this.$emit("upvoted");
          this.loadData();
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: 'error',
          message: e.message
        });
      }
    }
  }
  private async loadData() {
    if (UserModule.contractKit) {
      this.upvoted = await UserModule.contractKit.governanceContract.isUpvoting(UserModule.address);
    }
  }
  mounted() {
    this.loadData();
  }
  @Watch("proposalId")
  idChange() {
    this.loadData();
  }
};
