
import { UserModule } from '@/store/modules/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VoteValue } from '@maplabs/map-atlas-sdk';
import { AppModule } from '@/store/modules/app';
import { weiBigNumberToNormalValueFixed } from '@/utils/tools';

@Component
export default class ProposalVoteActionCard extends Vue{

  @Prop()
  private proposalId!: string;

  private options = [
    {
      value: VoteValue.Yes,
      number: "0"
    },
    {
      value: VoteValue.No,
      number: "0"
    },
    {
      value: VoteValue.Abstain,
      number: 0,
    }
  ];

  private totalVotes = "0";
  private voteValue = VoteValue.None;
  private voteRecord: any | null = null;


  @Watch("UserModule.address")
  private addressChange() {
    this.$nextTick(this.loadData);
  }

  tapOption(index: number) {
    this.voteValue = this.options[index].value;
  }

  get hasVote() {
    return this.voteRecord !== null && !this.voteRecord.votes.isZero();
  }

  async tapVote() {
    if (UserModule.contractKit) {
      try {
        AppModule.START_LOADING("Voting");
        const res = await UserModule.contractKit.governanceContract.vote(this.proposalId, this.voteValue);
        this.$emit("voted");
        this.loadData();
        AppModule.END_LOADING();
        if (res) {
          AppModule.TOAST({
            type: "success",
            message: "Success",
          });
        } else {
          AppModule.TOAST({
            type: "error",
            message: "Unknown error",
          });
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "error",
          message: e.message,
        });
      }
    }
  }

  async loadData() {
    if (UserModule.contractKit) {
      const [record, total] = await Promise.all([
        UserModule.contractKit.governanceContract.getVoteRecord(UserModule.address, this.proposalId),
        UserModule.contractKit.governanceContract.getVotes(this.proposalId),
      ]);
      this.totalVotes = weiBigNumberToNormalValueFixed(total.Yes.plus(total.No.plus(total.Abstain)));
      if (record && record.proposalID.toString() === this.proposalId + "") {
        this.voteRecord = record;
        this.options.forEach((item) => {
          if (item.value === record.value as VoteValue) {
            item.number = weiBigNumberToNormalValueFixed(record.votes);
          }
        })
      }
    }
  }

  mounted() {
    this.loadData();
  }
};
