import { render, staticRenderFns } from "./ProposalApprovalCard.vue?vue&type=template&id=16f8554e&scoped=true&"
import script from "./ProposalApprovalCard.vue?vue&type=script&lang=ts&"
export * from "./ProposalApprovalCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProposalApprovalCard.vue?vue&type=style&index=0&id=16f8554e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16f8554e",
  null
  
)

export default component.exports